import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import useSiteSettings from '~utils/useSiteSettings'
import NewsletterSignupForm from '~components/NewsletterSignupForm'
import { X } from '~components/Svg'
import { useSiteState } from '~context/siteContext'

const PopUp = ({ className }) => {

	const { popUpHeading, popUpPlaceholderText, popUpSuccessMessage, popUpButtonText } = useSiteSettings()
	const [ siteState, setSiteState ] = useSiteState()
	const [success, setSuccess] = useState()

	// useEffect(() => {
	// 	if(success){
	// 		setTimeout(() => {
	// 			setSiteState(prevState => ({
	// 				...prevState,
	// 				popUp: false,
	// 			}))
	// 		}, 4000)
	// 	}
	// }, [success])

	return (
		<Wrap className={className} show={siteState.popUp}>
			<Inner>
				<Close onClick={() => setSiteState(prevState => ({
					...prevState,
					popUp: false,
				}))}><Cross/></Close>
				<Content show={!success}>
					<Heading>{popUpHeading}</Heading>
					<NewsletterSignupForm placeHolder={popUpPlaceholderText} SubmitButtonText={popUpButtonText} onSuccess={() => setSuccess(true)}/>
				</Content>
				<Success show={success}>
					<SuccessText>
						{popUpSuccessMessage}
					</SuccessText>
					<CloseButtonContainer>
						<CloseButton className='h5' onClick={() => setSiteState(prevState => ({
							...prevState,
							popUp: false,
						}))}>Close</CloseButton>
					</CloseButtonContainer>
				</Success>
			</Inner>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 680px;
	box-sizing: border-box;
	border-radius: 40px;
	width: 100%;
	background-color: var(--orange);
	z-index: 5;
	color: var(--navy);
	opacity: ${ props => props.show ? '1' : '0'};
	pointer-events: ${ props => props.show ? 'inherit' : 'none'};
	transition: opacity 0.25s;
	${mobile}{
		max-width: calc(100% - (var(--m) * 2))
	}
`
const Inner = styled.div`
	position: relative;
	padding: var(--l) var(--xxl);
`
const Content = styled.div`
	opacity: ${ props => props.show ? '1' : '0'};
	pointer-events: ${ props => props.show ? 'inherit' : 'none'};
	transition: opacity 0.25s;
`
const Heading = styled.h4`
	text-align: center;
	margin-bottom: var(--l);
	${mobile}{
		font-size: 30px;
	}
`
const Close = styled.button`
	position: absolute;
	right: 34px;
	top: 34px;
	width: 30px;
	z-index: 2;
	${mobile}{
		width: 21px;
	}
`
const Cross = styled(X)`
`
const Success = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	opacity: ${props => props.show ? '1' : '0'};
	pointer-events: ${props => props.show ? 'inherit' : 'none'};
	transition: opacity 0.25s;
	padding: var(--xxl);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`
const SuccessText = styled.h2`
	text-align: center;
`
const CloseButtonContainer = styled.div`
	text-align: center;
`
const CloseButton = styled.button`
	position: relative;
	background-color: var(--navy);
	color: var(--orange);
	padding: 0 var(--s);
	height: 72px;
	border: 4px solid var(--navy);
	border-radius: 22px;
	min-width: 220px;
	:hover{
		color: var(--navy);
		background-color: var(--orange);
	}
`
PopUp.propTypes = {
	className: PropTypes.string,
	show: PropTypes.bool,
}

export default PopUp