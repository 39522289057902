import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '../utils/useSiteSettings'
import { mobile, tablet } from '~styles/global'
import { LogoCentered, LogoLeft, InstaLogo } from '~components/Svg'
import RichText from '~components/RichText'
import SanityLink from '~components/SanityLink'

const Footer = ({ className }) => {
	const { copyrightStatement, footerAcknowledgement, email, telephoneNumber, instagramUrl } = useSiteSettings()

	return(
		<Wrap className={className}>
			<Section>
				<LogoMob/>
				<Top>
					<LogoDesk/>
					<IconsDesk>
						<Flag src='/images/flags1.png'/>
						<Flag src='/images/flags2.png'/>
						<Flag src='/images/flags3.png'/>
					</IconsDesk>
				</Top>
				<Copyright className='sm'>© { new Date().getFullYear()} {copyrightStatement}</Copyright>
				<Ack className='sm'>
					<RichText content={footerAcknowledgement}/>
				</Ack>
				<IconsMob>
					<Flag src='/images/flags1.png'/>
					<Flag src='/images/flags2.png'/>
					<Flag src='/images/flags3.png'/>
				</IconsMob>
				<Line/>
				<Social className='sm'>
					<div>Need to chat?</div>
					<InlineBlock>Email us: 
						<Email 
							link={{
								url: `mailto:${email}`
							}}>
							{email}
						</Email>
					</InlineBlock>
					<InlineBlock>Or call: 
						<Tel link={{
							url: `tel:${telephoneNumber}`
						}}>
							{telephoneNumber}
						</Tel>
					</InlineBlock>
					<InstaLink 
						link={{
							linkType: 'external',
							url: instagramUrl,
							blank: true
						}}>
						<Instagram />
					</InstaLink>	
				</Social>
				<Badges>
					<UluruBadge src='/images/USFHLogoTransparentBackground.png'/>
					<IACSigBadge src='/images/IartC_Signatory_Logo_Colour.png'/>
				</Badges>
			</Section>
		</Wrap>
	)
}

const Wrap = styled.footer`
	margin-bottom: var(--m);
`
const LogoMob = styled(LogoCentered)`
	display: none;
	${mobile}{
		display: block;
		grid-column: span 12;
		max-width: 164px;
		margin: 0 auto var(--s);
	}
`
const Top = styled.div`
	grid-column: span 12;
	display: flex; 
	justify-content: space-between;
	align-items: center;
	margin-bottom: var(--m);
`
const LogoDesk = styled(LogoLeft)`
	max-width: 275px;
	width: 100%;
	${mobile}{
		display: none;
	}
`
const IconsDesk = styled.div`
	${mobile}{
		display: none;
	}
`
const Copyright = styled.div`
	grid-column: span 12;
	margin-bottom: var(--m);
`
const IconsMob = styled.div`
	display: none;
	${mobile}{
		display: flex;
		justify-content: center;
		margin-top: var(--m);
		margin-bottom: var(--m);
		grid-column: span 12;
	}
`
const Flag = styled.img`
	margin-left: 15px;
	width: 56px;
`
const Ack = styled.div`
	grid-column: span 12;
	margin-bottom: var(--l);
`
const Badges = styled.div`
	grid-column: span 12;
	${mobile}{
		display: flex;
		align-items: center;
		flex-direction: column;
	}
`
const UluruBadge = styled.img`
	width: 174px;
	margin-right: var(--m);
	${mobile}{
		width: 150px;
		margin-right: 0;
		margin-bottom: var(--s);
	}
`
const IACSigBadge = styled.img`
	width: 300px;
	${mobile}{
		width: 200px;
		margin-bottom: var(--s);
	}
`
const Social = styled.div`
	display: flex;
	grid-column: span 12;
	margin-bottom: var(--l);
	white-space: nowrap;
	flex-wrap: wrap;
`
const InlineBlock = styled.div`
	margin-left: var(--l);
	display: flex;
	${tablet}{
		width: 100%;
		margin-left: 0;
		margin-top: var(--s);
	}
`
const Email = styled(SanityLink)`
	margin-left: var(--xs);
	text-decoration: underline;
`
const Tel = styled(SanityLink)`
	margin-left: var(--xs);
`
const Line = styled.div`
	grid-column: span 12;
	border-bottom: 3px solid;
	margin-bottom: var(--m);
`
const InstaLink = styled(SanityLink)`
	width: 28px;
	margin-left: auto;
	${tablet}{
		margin-top: var(--s);
		margin-left: 0;
	}
`
const Instagram = styled(InstaLogo)`
	width: 100%;
`
Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer
