import React from 'react'
import PropTypes from 'prop-types'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import styled from '@emotion/styled'
import Image from '~components/Image'
import { ImageCaption, ImageWithCaption, DoubleImage, Quote, Attribution, QuoteBox } from '~styles/global'
import PhaseIcon from '~components/PhaseIcon'
import { css } from '@emotion/react'
import { mobile } from '~styles/global'
import Button from '~components/Button'
import TeamMembers from '~components/TeamMembers'

const serializers = {
	marks: {
		internalLink: props => {
			return <Link to={resolveLink(props.mark.page)}>{props.children}</Link> 
		},
		link: props => {
			if(props.mark.linkType === 'internal'){
				return <Link to={resolveLink(props.mark.document)}>{props.children}</Link> 
			} else {
				return <a href={props.mark?.url} target={props.mark.blank ? '_blank' : '_self'} rel='noreferrer'>{props.children}</a>
			}
		},
	},
	types: {
		block: props => {
			const {style = 'normal'} = props.node 
			if (props.children[0] === '') {
				return <br/>
			}
			if (style === 'h4') {
				return <p>{props.children}</p>
			}
			if (style === 'h3') {
				return <h5>{props.children}</h5>
			}
			if (style === 'normal') {
				return <p className='sm'>{props.children}</p>
			}
			return BlockContent.defaultSerializers.types.block(props)
		},
		imageWithCaption: props => {
			return (
				<ImageWithCaption>
					<Image image={props.node} />
					<ImageCaption className='sm'><BlockContent blocks={props?.node?.caption}/></ImageCaption>
				</ImageWithCaption>
			)
		},
		doubleImage: props => {
			return (
				<DoubleImage>
					<div>
						<Image image={props?.node?.leftImage} />
						<ImageCaption className='sm' css={css`${mobile}{ display: none;}`}>
							<BlockContent blocks={props?.node?.leftImage?.caption}/>
						</ImageCaption>
					</div>
					<div>
						<Image image={props?.node?.rightImage} />
						<ImageCaption className='sm' css={css`${mobile}{ display: none;}`}>
							<BlockContent blocks={props?.node?.rightImage?.caption}/>
						</ImageCaption>
					</div>
				</DoubleImage>
			)
		},
		phaseIcon: props => {
			return (
				<PhaseIcon step={props?.node?.selectedStep} steps={props?.node?.totalSteps}/>
			)
		},
		button: props => {
			return (
				<Button link={props?.node?.link} className='sm'>{props?.node?.text} +</Button>
			)
		},
		teamMembers: props => {
			return (
				<TeamMembers content={props.node}/>
			)
		},
		quote: props => {
			return (
				<QuoteBox>
					<Quote >
						<BlockContent blocks={props?.node?.quote}/>
					</Quote>
					<Attribution className='sm'>{props?.node?.attribution}</Attribution>
				</QuoteBox>
			)
		}
	},	
	list: props => {
		if (props.type === 'bullet') {
			return(
				<ul className='sm'>{props.children}</ul>
			)
		} else {
			return(
				<ol className='sm'>{props.children}</ol>
			)
		}
	}	
}

const RichText = ({ content, styles, border }) => (
	styles ? 
		<Styles border={border}>
			<BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>
		</Styles>
		:
		<BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>
)

const Styles = styled.div`
	> div {
		margin-top: ${props => props.border ? 'var(--xl)' : 'var(--xxxl)'};
	}
	h2{
		margin-top: ${props => props.border ? 'var(--xl)' : 'var(--xxl)'};
		margin-bottom: var(--xl);
		&.credit{
			margin-top: var(--m);
			margin-bottom: var(--l);
		}
	}
	h5{
		margin-top: var(--xxl);
		margin-bottom: var(--l)
	}
	p{
		margin-bottom: var(--xl);
		&.sm{
			margin-bottom: var(--m);
		}
	}
	ul, ol {
		margin-left: var(--m);
		list-style-position: outside;
		margin-bottom: var(--xxl);
		margin-top: var(--l);
	}
	li{
		padding-left: var(--m);
		margin-bottom: var(--l);
		${mobile}{
			padding-left: var(--s);
		}
	}
	p > a{
		text-decoration: underline;
	}
`

RichText.propTypes = {
	content: PropTypes.array,
	styles: PropTypes.bool,
	border: PropTypes.bool,
}

export default RichText
