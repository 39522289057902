import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import GlobalStyles from '~styles/global'
import Header from '~components/Header'
import Footer from '~components/Footer'
import smoothscroll from 'smoothscroll-polyfill'
import { mobile } from '~styles/global'
import Transition from '~components/Transition'
import { useSiteState, useCart } from '../context/siteContext'
import styled from '@emotion/styled'
import { useLocation } from '@reach/router'
import '~styles/static.css'
import Acknowledgement from '~components/Acknowledgement'
import { AnimatePresence, motion } from 'framer-motion'
import Cookies from 'js-cookie'
import PopUp from '~components/PopUp'
import useFontsReady from '~utils/useFontsReady'

const Layout = ({ children }) => {

	const [siteState, setSiteState] = useSiteState()
	const { closeCart } = useCart()
	const location = useLocation()
  
	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	// Close the cart whenever the route changes
	useEffect(() => {
		window.setTimeout(() => {
			setSiteState(prevState => ({
				...prevState,
				menuOpen: false,
			}))
		}, 500)
		// eslint-disable-next-line
  }, [location])

	useEffect(() => {
		if (!Cookies.get('acknowledgementClosed') && !process.env.GATSBY_PREVIEW && location.pathname === '/') {
			setSiteState(prevState => ({
				...prevState,
				acknowledgement: true,
			}))
		} else {
			setSiteState(prevState => ({
				...prevState,
				acknowledgement: false,
			}))
		}
	}, [])

	// if(siteState.acknowledgement === undefined) return null

	return (
		<>
			<GlobalStyles />
			{(siteState?.menuOpen || siteState?.acknowledgement || siteState?.popUp) && 
				<Global styles={css`
					body,html{
						overflow: hidden;
					}
				`}/>
			}
			{location.pathname === '/' && <StyledAcknowledgement show={siteState?.acknowledgement}/>}
			<Header 
				minimal={location?.pathname === '/' || siteState?.acknowledgement} 
				artist={location?.pathname.includes('artists') && location?.pathname !== '/artists'}
				contactPage={location?.pathname === '/contact'}
			/>
			<main hide={siteState?.acknowledgement === undefined || siteState?.acknowledgement === true}>
				<Transition>
					<div css={css`
            min-height: calc(100vh);
            display: flex;
            flex-direction: column;
            ${mobile}{
              min-height: calc(100vh);
            }
          `}>
						{children}
						<Footer css={css`margin-top: auto;`}/>
					</div>
				</Transition>
			</main>
			<PopUp/>
			<Overlay 
				onClick={() => setSiteState(prevState => ({
					...prevState,
					popUp: false,
				}))}
				show={siteState.popUp}
			/>
		</>
	)
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	background-color: var(--black);
	pointer-events: ${ props => props.show ? 'all' : 'none'};
	opacity: ${ props => props.show ? '0.7' : '0'};
	transition: opacity 0.3s;
	z-index: 4;
`
const StyledAcknowledgement = styled(Acknowledgement)`
	pointer-events: ${ props => props.show ? 'all' : 'none'};
	opacity: ${ props => props.show ? '1' : '0'};
	transition: ${ props => props.show ? '0s' : '0.5s'};
	`

Layout.propTypes = {
	children: PropTypes.node,
}

export default Layout
