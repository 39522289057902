import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useSiteState } from '~context/siteContext'

const PhaseIcon = ({ className, step, steps }) => {
	const [siteState, setSiteState] = useSiteState()
	const [backgroundColor, setBackgroundColor] = useState()
	const buttonRef = useRef()

	useEffect(() => {
		const offsetTop = buttonRef?.current?.getBoundingClientRect()?.top
		const documentHeight = window?.document?.body?.offsetHeight

		if (offsetTop < documentHeight / 2) {
			setBackgroundColor(siteState?.topBgColor)
		} else {
			setBackgroundColor(siteState?.bottomBgColor)
		}
		// setOffset(normalisedOffset)
	}, [siteState?.topBgColor, siteState?.bottomBgColor])

	return (
		<Wrap className={className} ref={buttonRef}>
			{[...Array(steps)]?.map((s, i) => (
				<Step key={'step' + i} className='h5' active={i + 1 === step} color={siteState?.color} bgColor={backgroundColor}>
					{console.log('siteState', siteState.bgColor)}
					{i + 1}
				</Step>
			))}
		</Wrap>
	)
}

const Wrap = styled.div`
	display: flex;
	justify-content: center;
	margin: calc(var(--xxxl) + var(--s)) 0 var(--xl);
`
const Step = styled.div`
	height: 50px;
	width: 50px;
	border-radius: 50px;
	border: 5px solid;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 60px;
	position: relative;
	color: ${ props => props.active ? props.bgColor : 'inherit'};
	border-color: ${ props => props.color};
	background-color: ${ props => props.active ? props.color : 'transparent'};
	${mobile}{
		margin-right: 40px;
	}
	:before{
		content: '';
		border-top: 5px solid;
		border-color: ${ props => props.color};
		width: 65px;
		position: absolute;
		left: 100%;
		${mobile}{
			width: 45px;
		}
	}
	:last-of-type{
		margin-right: 0px;
		:before{
			content: none;
		}
	}
`

PhaseIcon.propTypes = {
	className: PropTypes.string
}

export default PhaseIcon