import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { mobile, tablet } from '~styles/global'
import axios from 'axios'
import Button from '~components/Button'

const NewsletterSignupForm = ({ className, placeHolder, SubmitButtonText, onSuccess }) => {
	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)
	const [failure, setFailure] = useState(false)


	const sendToEmailOctopus = async (email) => {
		await axios({
			method: 'POST',
			url: '/api/sign-up-form',
			data: {
				email_address: email,
			},
		}).then(res => {
			if (res.data?.status === 'success' || (res.data?.error?.config?.data.includes('SUBSCRIBED'))) {
				onSuccess()
			} else if (res.data?.status === 'error') {
				setFailure(true)
			}
		})
	}


	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			submit()
		}
		else{
			setErrors(newErrors)
		}
	}

	const submit = () => {
		sendToEmailOctopus(values.email)
	}

	const errorFields = errors.map(error => error.field)

	return (
		<Wrap className={className}>
			<FormWrap css={css`
        opacity: ${success ? '0' : '1'};
        pointer-events: ${success ? 'none' : 'inherit'};
        `}>
				<Form onSubmit={e => validate(e)} noValidate className='m'>
					<Input
						type="email"
						name="email"
						placeholder={placeHolder}
						css={css`
							color: var(--red);
							::placeholder{
								color: ${errorFields.includes('email') ? 'var(--red)' : 'var(--red)'};
							}    
          `}	
						onChange={e => setValues({...values, email: e.target.value})}
						onBlur={() => setFailure(false)}
					/>
					{errors?.length > 0 &&
            <Errors>
            	{errors.map(error => <p>{error.message}</p>)}
            </Errors>
					}
					{failure &&
            <Errors>
            	Please try again
            </Errors>
					}
					<SubmitContainer>
						<Submit type="submit" className='button' className='h5'>
							{SubmitButtonText}
						</Submit>
					</SubmitContainer>

				</Form>
			</FormWrap>
		</Wrap>
	)
}

const Wrap = styled.div`
  position: relative;
`

const FormWrap = styled.div`
  position: relative;
  transition: opacity 0.3s;
	width: 100%;
`
const Form = styled.form`
`
const Input = styled.input`
	padding: var(--s);
	height: 68px;
	border: none;
	width: 100%;
	box-sizing: border-box;
	border-radius: 4px;
	background: transparent;
	font-size: inherit;
	font-family: inherit;
	margin-right: 20px;
	color: var(--navy);
	&::placeholder{
		color: var(--navy);
	}
	background-color: var(--midOrange);
	&:-webkit-autofill {
		box-shadow: none !important;
		color: var(--red);
		&:active, &:focus, &:hover {
			box-shadow: none !important;
			color: var(--red);
		}
	}
	&:-internal-autofill-selected {
		background-color: transparent !important;
	}
	${mobile}{
		margin-right: 0px;
		margin-bottom: 20px;
	}
`
const SubmitContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: var(--l);
`
const Submit = styled.button`
	position: relative;
	top: -1px;
	background-color: var(--navy);
	color: var(--orange);
	padding: 0 var(--s);
	height: 72px;
	border: 4px solid var(--navy);
	border-radius: 22px;
	min-width: 220px;
	:hover{
		color: var(--navy);
		background-color: var(--orange);
	}
`
const Errors = styled.div`
	position: relative;
  margin-top: var(--xs);
`
const Arrow = styled.span`
	display: inline-block;
	margin-right: var(--xs);
	position: relative;
	top: -1px;
	${mobile}{
		margin-right: var(--xxs);
	}
`
NewsletterSignupForm.propTypes = {
	className: PropTypes.string,
	onSuccess: PropTypes.func,
	showName: PropTypes.bool,
}

export default NewsletterSignupForm
