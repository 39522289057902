import React from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import SanityLink from '~components/SanityLink'
import ParallaxArtefact from '~components/ParallaxArtefact'
import Image from '~components/Image'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 1025px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp

const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`

  body {
		font-family: 'Brunswick Grotesque', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 32px;
		line-height: 125%;
		text-align: justify;
		letter-spacing: 0.02em;
		color: var(--white);
		font-feature-settings: "calt";
		transition: background-color 1s;
		${tablet}{
			font-size: 24px;
		}
  }

  :root{
    --white: #ffffff;
    --black: #000000;
		--blue: #0057FF;
		--darkBlue:#002E86;
		--lightBlue: #88C6FF;
		--navy: #2C2746;
		--green: #10AB73;
		--darkGreen: #057258;
		--lightGreen: #6FE36D;
		--yellow: #FFD80D;
		--darkYellow: #DEAD00;
		--lightYellow: #FFEE57;
		--orange: #FF3D00;
		--darkOrange: #C00000;
		--midOrange:#FF845D;
		--lightOrange: #FFA471;
		--pink: #FF005C;
		--darkPink: #3F227B;
		--lightPink: #FF9ABE;
		--gradienOrangeRed: linear-gradient(180deg, #FF9901 2.78%, #FF4C00 80.91%);
		--gradientBlueGreen: linear-gradient(180deg, #1D324B 0%, #00794D 100%);
		--gradientPinkBlue: linear-gradient(180deg, #B81A50 0%, #1D324A 92.4%);
		--clay: #DAD6BF;
		--gradientGreenBlue: linear-gradient(180deg, #005B45 0%, #185568 100%);
		--gradient2: linear-gradient(360deg, #FF4F8F 44.72%, #FF465C 59.64%, #FF3B6A 76.97%, #FF4D01 93.36%);
		--gradient5: linear-gradient(360deg, #FF4F8F 7.01%, #FF465C 47.91%, #FF4D01 100%);
		--gradient3: linear-gradient(180deg, #FF3C82 2.78%, #FF4C00 80.91%);
		--ackGradient: linear-gradient(180deg, #14263B 2.78%, #00794D 80.91%);
    --xxs: 5px;
    --xs: 10px;
    --s: 20px;
    --m: 30px;
    --l: 40px;
    --xl: 50px;
    --xxl: 80px;
    --xxxl: 100px;
    --xxxxl: 150px;
  }

  ${tablet}{
    :root{
      --xxs: 3px;
      --xs: 7px;
      --s: 15px;
      --m: 20px;
      --l: 30px;
      --xl: 35px;
      --xxl: 40px;
      --xxxl: 60px;
      --xxxxl: 120px;
    }
  }

	.ReactCollapse--collapse {
		transition: height 0.6s cubic-bezier(0.5, 1, 0.89, 1);
	}

  p{
    margin-bottom: 1.2em;
    &:last-child{
      margin-bottom: 0;
    }
  }
  h1,
  .h1 {
		font-family: 'Staunch Titling XCondensed', sans-serif;
		font-size: 110px;
		line-height: 90%;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		font-weight: 900;
    ${tablet} {
			font-size: 45px;
    }
		p{
			margin-bottom: 0.55em;
			&:last-child{
				margin-bottom: 0;
			}
		}
  }

	h2,
	.h2 {
		font-family: 'Staunch Titling XCondensed', sans-serif;
		font-style: normal;
		font-weight: 800;
		font-size: 70px;
		line-height: 90%;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		${tablet}{
			font-size: 45px;
		}
	}

	h3,
  .h3 {
		font-family: 'Staunch Titling Condensed', sans-serif;
		font-weight: 900;
		font-size: 55px;
		line-height: 95%;
    ${tablet} {
			font-size: 45px;
    }
  }


	h4,
  .h4 {
		font-family: 'Staunch Titling Condensed', sans-serif;
		font-weight: 900;
		font-size: 50px;
		line-height: 95%;
		letter-spacing: 0.04em;
    ${tablet} {
			font-size: 25px;
    }
  }

	h5,
	.h5 {
		font-family: 'Staunch Titling Condensed', sans-serif;
		font-style: normal;
		font-weight: 900;
		font-size: 40px;
		line-height: 95%;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		${tablet}{
			font-size: 30px;
		}
	}

	h6,
	.h6{
		font-family: 'Staunch Titling Condensed', sans-serif;
		font-weight: 900;
		font-size: 30px;
		line-height: 95%;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		${tablet}{
			font-size: 22px;
		}
	}

	.m{
		font-size: 22px;
		line-height: 125%;
		${tablet}{
			font-size: 18px;
		}
	}

	.sm{
		font-size: 18px;
		line-height: 125%;
		${tablet}{
			font-size: 16px;
		}
	}

	.xs{
		font-size: 15px;
		line-height: 130%;
		letter-spacing: 0.04em;
		${tablet}{			
		}
	}
`

export const MajorTitle = styled.h1`
	background-color: ${ props => props.bgColor};
	color: ${ props => props.color};
	padding: var(--m) var(--xl);
	display: inline-block;
	border-radius: 40px;
	font-size: 139px;
	line-height: 85%;
	${tablet}{
		font-size: 46px;
		padding: var(--m) var(--m);
		border-radius: 16px;
	}
`
export const TitleContainer = styled.div`
	grid-column: span 12;
	text-align: center;
	margin-top: var(--xxl);
	margin-bottom: var(--xxxl);
	position: relative;
`
export const Artefact = styled(ParallaxArtefact)`
	position: absolute;
	top: ${ props => props.top };
	bottom: ${ props => props.bottom };
	left: ${ props => props.left };
	right: ${ props => props.right };
	z-index: 2;
	mix-blend-mode: ${ props => (props.type === 'jpg') ? 'multiply' : 'initial'};
	filter: ${ props => (props.type === 'jpg') ? 'none' : 'drop-shadow(1px 25px 15px rgba(0, 0, 0, 0.64))'};
	${mobile}{
		filter: ${ props => (props.type === 'jpg') ? 'none' : 'drop-shadow(6px 20px 7px rgba(0, 0, 0, 0.37))'};
	}
`
export const ArtefactImage = styled(Image)`
	width: ${ props => props.width / 2}px;
	transform: ${ props => props.transform ? props.transform : 0};
	${desktop}{
		width: ${ props => (props.width / 2) * 0.7}px;
	}
	${tablet}{
		width: ${ props => (props.width / 2) * 0.55}px;
	}
	${mobile}{
		width: ${ props => (props.width / 2) * 0.4}px;
	}
`
export const ImageWithCaption = styled.div`
	margin-top: var(--xl);
	margin-bottom: var(--xl);
`
export const ImageCaption = styled.div`
	margin-top: var(--xs);
	p{
		margin-bottom: 0;
	}
`
export const DoubleImage = styled.div`
	margin-top: var(--xl);
	margin-bottom: var(--xl);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 30px;
	grid-row-gap: var(--l);
	${mobile}{
		grid-column-gap: 20px;
	}
`
export const QuoteBox = styled.div`
	background: rgba(0, 0, 0, 0.1);
	border-radius: 50px;
	padding: 1px var(--xl) var(--xl);
	box-sizing: border-box;
	text-align: center;
	${mobile}{
		border-radius: 32px;
	}
`
export const Quote = styled.h5`
	margin-top: 0px;
`
export const Attribution = styled.div`
	
`

const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.55em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

	input, select {
		border-radius: 0;
		-webkit-appearance: none;
	}

  input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	/* Prevent safari blue links */
	button, option {
    color: inherit;
  }

`

export default GlobalStyles
