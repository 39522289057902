import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        siteSocialImage: socialImage {
          asset {
            _id
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
				acknowledgement: _rawAcknowledgement
				buttonText
				mainMenu {
					_key
					text
					link {
						...link
					}
				}
				footerAcknowledgement: _rawFooterAcknowledgement(resolveReferences: {maxDepth: 2})
				copyrightStatement
				popUpHeading
				popUpPlaceholderText
				popUpSuccessMessage
				popUpButtonText
				contactButtonText
				instagramUrl
				email
				telephoneNumber
      }
    }
    `)

	return siteSettings?.sanitySiteSettings
}

export default useSiteSettings
