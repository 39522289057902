import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import SanityLink from '~components/SanityLink'
import { useSiteState } from '~context/siteContext'

const Button = ({ className, children, link, large, onClick, xLarge}) => {
	const [siteState, setSiteState] = useSiteState()
	const buttonRef = useRef()
	const [backgroundColor, setBackgroundColor] = useState(undefined)
	
	useEffect(() => {
		const offsetTop = buttonRef?.current?.getBoundingClientRect()?.top
		const documentHeight = window?.document?.body?.offsetHeight

		if (offsetTop < documentHeight / 2) {
			setBackgroundColor(siteState?.topBgColor)
		} else {
			setBackgroundColor(siteState?.bottomBgColor)
		}
	}, [siteState?.topBgColor, siteState?.bottomBgColor])

	if (onClick && xLarge) {
		return (
			<StyledXLargeFunctionalButton 
				className={className} 
				onClick={onClick}
				bgColor={backgroundColor} 
				color={siteState.color}
				ref={buttonRef}
			>
				{children}
			</StyledXLargeFunctionalButton>
		)
	} else 	if (onClick) {
		return (
			<StyledFunctionalButton 
				className={className} 
				onClick={onClick}
				bgColor={backgroundColor} 
				color={siteState.color}
				ref={buttonRef}
			>
				{children}
			</StyledFunctionalButton>
		)
	}  else if (link && large) {
		return (
			<LargeRefWrapper ref={buttonRef} className={className}>
				<StyledButtonLarge 
					className={className + ' h5'} 
					link={link} 				
					bgColor={backgroundColor} 
					color={siteState.color}
				>
					{children}
				</StyledButtonLarge>
			</LargeRefWrapper>
		)
	} else if (link && xLarge) {
		return (
			<XlargeRefWrapper ref={buttonRef} className={className} >
				<StyledButtonXLarge 
					link={link} 
					bgColor={backgroundColor} 
					color={siteState.color}
				>
					{children}
				</StyledButtonXLarge>
			</XlargeRefWrapper>
		)
	} else if (link){
		return (
			<RefWrapper ref={buttonRef} className={className} >
				<StyledButton 
					link={link} 				
					bgColor={backgroundColor} 
					color={siteState.color}
				>
					{children}
				</StyledButton>
			</RefWrapper>
		)
	} else return (<></>)
}

const StyledFunctionalButton = styled.button`
	  border: 3px solid;
		border-radius: 8px;
		text-transform: uppercase;
		align-items: center;
		display: inline-flex;
		justify-content: center;
		height: 40px;
		padding: 11px 27px 9px;
		box-sizing: border-box;
		min-width: 169px;
		letter-spacing: 0.04em;
		color: ${ props => props.color};
		transition: color 0.25s, background-color 0.25s, border-color 0.25s;
		:hover{
			color: ${ props => props.bgColor};
			background-color: ${ props => props.color};
			border-color: ${ props => props.color}
		}
`
const StyledXLargeFunctionalButton = styled(StyledFunctionalButton)`
	border-radius: 40px;
	border: 4px solid;
	padding: var(--s) var(--m);
	height: 119px;
	${mobile}{
		border-radius: 25px;
		height: 82px;
	}
`

const StyledButton = styled(SanityLink)`
	border: 3px solid;
	border-radius: 8px;
	text-transform: uppercase;
	align-items: center;
	display: inline-flex;
	justify-content: center;
	height: 40px;
	padding: 11px 27px 9px;
	box-sizing: border-box;
	min-width: 169px;
	letter-spacing: 0.04em;
	color: ${ props => props.color};
	transition: color 0.25s, background-color 0.25s, border-color 0.25s;
	white-space: nowrap;
	:hover{
		color: ${ props => props.bgColor};
		background-color: ${ props => props.color};
		border-color: ${ props => props.color}
	}
`

const LargeRefWrapper = styled.div`
	height: 100px;
	display: inline-block;
	${mobile}{
		height: 82px;
	}
`
const StyledButtonLarge = styled(StyledButton)`
	height: 100px;
	padding: 10px 30px;
	border: 5px solid;
	padding: var(--s) var(--xl);
	min-width: 415px;
	${mobile}{
		min-width: 275px;
		height: 82px;
	}
`
const XlargeRefWrapper = styled.div`
		height: 119px;
		display: inline-block;
		${mobile}{
			height: 82px;
		}
`
const StyledButtonXLarge = styled(StyledButton)`
	border-radius: 40px;
	border: 4px solid;
	padding: var(--s) var(--m);
	height: 119px;
	${mobile}{
		border-radius: 25px;
		height: 82px;
	}
`
const RefWrapper = styled.div`
	height: 40px;
	display: inline-block;
`


Button.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	link: PropTypes.string,
	large: PropTypes.bool,
	onClick: PropTypes.func,
	xLarge: PropTypes.bool,
}

export default Button