import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useSiteState } from '~context/siteContext'
import Cookies from 'js-cookie'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from '~components/RichText'
import Section from '~components/Section'
import { LogoIcon } from '~components/Svg'
import {Link} from 'gatsby'
import Button from '~components/Button'
import Footer from '~components/Footer'

const Acknowledgement = ({ className }) => {

	const [siteState, setSiteState] = useSiteState()
	const { acknowledgement } = useSiteSettings()

	const closeModal = () => {
		setSiteState(prevState => ({
			...prevState,
			acknowledgement: false
		}))
		Cookies.set('acknowledgementClosed', true, { expires: 2 })
	}

	return (
		<>
			<Wrap className={className}>
				<Section>
					<LogoSection>
						<LogoLink to={'/'}>
							<LogoSmall />
						</LogoLink>
					</LogoSection>
					<Ack><RichText content={acknowledgement}/></Ack>
					<ButtonContainer>
						<StyledButton className='h4' xLarge onClick={() => closeModal()}>Continue to Solid Lines</StyledButton>
					</ButtonContainer>
				</Section>
				<Footer/>
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: var(--ackGradient);
	z-index: 3;
	overflow: scroll;
	color: var(--orange);
`
const Ack = styled.h1`
	grid-column: span 12;
	margin-top: var(--xl);
	margin-bottom: var(--xxxl);
	p {
		font-size: inherit;
		line-height: inherit;
	}
`
const ButtonContainer = styled.div`
	grid-column: span 12;
	margin-bottom: var(--xxxxl);
	${mobile}{
		text-align: center;
	}
`
const StyledButton = styled(Button)`
	color: #104442;
	background-color: var(--orange);
	border-color: var(--orange);
	:hover{
		background-color: transparent;
		color: var(--orange);
		border-color: var(--orange);
	}
`
const LogoSection = styled.div`
	grid-column: span 12;
	justify-content: center;
	height: 100px;
	display: flex;
	align-items: center;
`
const LogoLink = styled(Link)`
	width: 48px;
	position: relative;
	z-index: 10;
`
const LogoSmall = styled(LogoIcon)`

`

Acknowledgement.propTypes = {
	className: PropTypes.string
}

export default Acknowledgement