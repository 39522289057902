import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { motion } from 'framer-motion'

const ParallaxArtefact = ({ className, children }) => {

	const [offset, setOffset] = useState(undefined)
	const boundingRef = useRef()

	// update offset
	const getOffset = () => {
		const offsetTop = boundingRef?.current?.getBoundingClientRect()?.top
		const normalisedOffset = (offsetTop - window.innerHeight / 2) / 5
		setOffset(normalisedOffset)
	}

	// initially set offset
	useEffect(() => {
		const offsetTop = boundingRef?.current?.getBoundingClientRect()?.top
		const normalisedOffset = (offsetTop - window.innerHeight / 2) / 5
		setOffset(normalisedOffset)
	}, [])

	useEffect(() => {
		document.addEventListener('scroll', getOffset)
		return () => document.removeEventListener('scroll', getOffset)
	}, [boundingRef?.current ])

	return (
		<Wrap className={className} ref={boundingRef}>
			<motion.div 
				style={{ y: offset }}
			>
				{children}
			</motion.div>
		</Wrap>
	)
}

const Wrap = styled.div`
	pointer-events: none;
`

ParallaxArtefact.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
}

export default ParallaxArtefact