import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import BlockContent from '@sanity/block-content-to-react'
import RichText from '~components/RichText'

const TeamMembers = ({ className, content }) => {
	return (	
		<Wrap className={className}>
			<div>
				<Heading>{content.memberLeft?.title}</Heading>
				<Image image={content?.memberLeft?.image} />
				<Text className='sm'>
					<RichText content={content?.memberLeft?.text}/>
				</Text>
			</div>
			<div>
				<Heading>{content.memberRight?.title}</Heading>
				<Image image={content?.memberRight?.image} />
				<Text className='sm'>
					<RichText content={content?.memberRight?.text}/>
				</Text>
			</div>
		</Wrap>
	)
}

const Wrap = styled.div`
	margin-top: var(--xl);
	margin-bottom: var(--xl);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 30px;
	grid-row-gap: var(--l);
	${mobile}{
		grid-column-gap: 20px;
		grid-template-columns: 1fr;
	}
`

const Text = styled.div`
	margin-top: var(--m);
	p{
		margin-bottom: 0;
	}
`
const Heading = styled.h5`
	margin-bottom: var(--l);
`
TeamMembers.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default TeamMembers