import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'
import { useLocation } from '@reach/router'
import styled from '@emotion/styled'
import useFontsReady from '~utils/useFontsReady'
import { useSiteState } from '../context/siteContext'

const Transition = ({ children }) => {
	const location = useLocation()
	const fontsReady = useFontsReady()
	const [siteState] = useSiteState()


	return (
		<>
			<InitialBlack hide={fontsReady && siteState.acknowledgement !== undefined}/>
			<AnimatePresence exitBeforeEnter>
				<div key={location.pathname}>
					<Black 
						initial={{ opacity: 1 }}
						animate={{ opacity: 0 }}
						exit={{ opacity: 1 }}
						transition={{ duration: 0.8, delay: 0 }}
					/>
					<motion.div 
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.5, delay: 0.4 }}
					>
						{children}
					</motion.div>
				</div>
			</AnimatePresence>
		</>
	)
}

const Black = styled(motion.div)`
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 99;
	background-color: var(--black);
	pointer-events: none;
`
const InitialBlack = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 99;
	background-color: var(--black);
	pointer-events: none;
	opacity: ${ props => props.hide ? '0' : '1'};
	transition: opacity 0.25s;
`

Transition.propTypes = {
	children: PropTypes.node,
}

export default Transition
