module.exports = {
	siteUrl: 'https://solidlinesfull.gatsbyjs.io',
	buildsUrl: 'https://solidlinesfull.gtsb.io',
	previewUrl: 'https://preview-solidlinesfull.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/2721b18b-7d6b-4f5e-895d-6d939f56148a',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	gTag: 'G-XXXXXXXXX'
}
